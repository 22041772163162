body[data-theme='light'] {
  --colors-primary: #fcd118;
  --colors-text: #333333;
  --colors-link: #1677ff;
  --colors-sub-text: #57606a;
  --colors-bg1: #f8f8f8;
  --colors-bg2: #ffffff;
  --box-shadow: 0 0 100px rgb(0 0 0 / 8%);

  font-size: 14px;
}

body[data-theme='dark'] {
  --colors-primary: #fcd118;
  --colors-background: #f8f8f8;
}
