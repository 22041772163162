/* fix anticon vertical align https://github.com/ant-design/ant-design/issues/13074#issuecomment-671093258 */
svg {
  vertical-align: baseline;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
  border-radius: 9999px;
}

::-webkit-scrollbar {
  width: 8px;
}

.app-sider-menu::-webkit-scrollbar {
  width: 6px;
}

.app-sider-menu::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.app-sider-menu:hover::-webkit-scrollbar-thumb {
  background-color: #898b8d;
}
